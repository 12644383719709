import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { MarketMakerQuoteStatusEnumValues, MarketMakerRequestStatusEnumValues } from '@forgd/supabase'
import {
  customDateSchema,
  RFQDetailsResult,
  TierSchemaResponse,
  RFQQuotesSummary,
  MarketMakerLeaderboardResponse,
  QuoteCreateSchema,
  RFQResult,
  MarketMakerLeaderboardQueryRequest,
  LiquidityQuotesByEngagementTypeResponse,
  QuoteUpdateSchema,
} from '../types'
import {
  ActiveRFQStatusFilterOptionEnumValues,
  ClosedRFQStatusFilterOptionEnum,
  ClosedRFQStatusFilterOptionEnumValues,
  RFQKPISummaryFilterOptionEnum,
  RFQKPISummaryFilterOptionEnumValues,
} from '@forgd/shared/enums'

const c = initContract()

export const liquidityContract = c.router({
  me: {
    method: 'GET',
    path: '/liquidity/me',
    responses: {
      200: z.object({
        id: z.string().uuid(),
        email: z.string().email(),
        marketMakerId: z.string(),
        marketMakerName: z.string(),
        marketMakerImage: z.string().nullable(),
      }),
    },
    summary: 'Get current user of liquidity portal.',
  },
  getKPIs: {
    method: 'GET',
    path: `/liquidity/kpis`,
    responses: {
      200: z.object({
        createdAt: customDateSchema,
        activeQuotesQty: z.number(),
        totalQuotesSubmitted: z.number(),
        totalQuotesAccepted: z.number(),
        activeProjectsQty: z.number(),
      }),
    },
    summary: 'Get market maker KPIs.',
  },
  getRFQKPIs: {
    method: 'GET',
    path: `/liquidity/rfq-kpis`,
    query: z.object({
      rfqId: z.string().uuid(),
      round: z.coerce.number().int().min(1).max(2),
      filter: z
        .enum(RFQKPISummaryFilterOptionEnumValues)
        .optional()
        .default(RFQKPISummaryFilterOptionEnum.AllReceivedQuotes),
    }),
    responses: {
      200: z.object({
        kpisSummary: z.array(TierSchemaResponse),
      }),
    },
    summary: 'Get RFQ KPIs.',
  },
  getActiveRFQs: {
    method: 'GET',
    path: `/liquidity/active`,
    query: z.object({
      status: z.enum(ActiveRFQStatusFilterOptionEnumValues),
    }),
    responses: {
      200: z.object({
        results: z.array(
          z.object({
            rfqId: z.string().uuid(),
            projectName: z.string(),
            projectImage: z.string().nullable(),
            projectTicker: z.string().nullable(),
            round: z.number(),
            status: z.enum(MarketMakerRequestStatusEnumValues),
            statusUpdatedAt: customDateSchema,
            receivedQuotesQty: z.number(),
            maxDaysForMarketMakerToSubmitQuotes: z.number().int().positive(),
            hasRoundQuotes: z.boolean(),
            myQuotes: z.array(
              z.object({
                round: z.number(),
                selectedForRequote: z.boolean(),
                status: z.enum(MarketMakerQuoteStatusEnumValues),
              }),
            ),
          }),
        ),
        count: z.number().int().nonnegative().finite(),
        quotesSubmittedAndInReviewCount: z.number().int().nonnegative().finite(),
        rfqsAcceptingQuotesCount: z.number().int().nonnegative().finite(),
      }),
    },
    summary: 'Get active rfqs for market maker.',
  },
  getClosedRFQsStatistic: {
    method: 'GET',
    path: `/liquidity/closed/stats`,
    responses: {
      200: z.object({
        totalParticipated: z.number(),
        myTotalSelected: z.number(),
        myTotalRejected: z.number(),
      }),
    },
    summary: 'Get closed rfqs statistic for market maker.',
  },
  getClosedRFQs: {
    method: 'GET',
    path: `/liquidity/closed`,
    query: z.object({
      status: z.enum(ClosedRFQStatusFilterOptionEnumValues).optional().default(ClosedRFQStatusFilterOptionEnum.All),
      page: z.number({ coerce: true }).int().positive().default(1),
      pageSize: z.number({ coerce: true }).int().positive().default(40),
      sort: z.string(),
      sortDirection: z.enum(['asc', 'desc']),
    }),
    responses: {
      200: z.object({
        results: z.array(
          z.object({
            rfqId: z.string().uuid(),
            statusUpdatedAt: customDateSchema,
            projectName: z.string(),
            projectTicker: z.string().nullable(),
            projectImage: z.string().nullable(),
            receivedQuotesQty: z.number(),
            selectedQuotesQty: z.number(),
            mmMaxQuotesQty: z.number(),
            mmSelectedQuotesQty: z.number(),
            round: z.number(),
            mmPerformanceStatus: z.number(),
          }),
        ),
        count: z.number(),
      }),
    },
    summary: 'Get closed rfqs for market maker.',
  },
  getRFQDetails: {
    method: 'GET',
    path: `/liquidity/rfq-details`,
    query: z.object({
      id: z.string().uuid(),
    }),
    responses: {
      200: RFQDetailsResult.extend({
        rfq: RFQResult,
        submitedQuotes: z.array(
          z.object({
            round: z.number(),
            selectedForRequote: z.boolean(),
            status: z.enum(MarketMakerQuoteStatusEnumValues),
            marketMakerId: z.string().nullable(),
          }),
        ),
      }),
    },
    summary: 'Get RFQ details.',
  },
  getMarketMakersLeaderboard: {
    method: 'GET',
    path: '/liquidity/market-makers-leaderboard',
    query: MarketMakerLeaderboardQueryRequest,
    responses: {
      200: MarketMakerLeaderboardResponse,
    },
    summary: 'Get market makers leaderboard',
  },
  getQuotesBreakdown: {
    method: 'GET',
    path: `/liquidity/round-quotes`,
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
      rfqId: z.string().uuid(),
      includeAllStatuses: z.string().transform((val) => val === 'true'),
    }),
    responses: {
      200: z.object({
        quotes: LiquidityQuotesByEngagementTypeResponse,
      }),
    },
    summary: 'Get quotes breakdown for the round by market maker.',
  },
  getRFQOutcomeRoundComparison: {
    method: 'GET',
    path: `/liquidity/outcome-round-comparison`,
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
      rfqId: z.string().uuid(),
    }),
    responses: {
      200: z.object({
        quotesSummary: RFQQuotesSummary,
        selectedQuotesSummary: RFQQuotesSummary,
        mySelectedQuotesSummary: RFQQuotesSummary,
      }),
    },
    summary: 'Get rfq outcome round comparison.',
  },
  getRFQRoundComparison: {
    method: 'GET',
    path: `/liquidity/round-comparison`,
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
      rfqId: z.string().uuid(),
    }),
    responses: {
      200: z.object({
        quotesSummary: RFQQuotesSummary,
        myQuotesSummary: RFQQuotesSummary,
      }),
    },
    summary: 'Get rfq round comparison.',
  },
  createQuote: {
    method: 'POST',
    path: `/liquidity/create-quote`,
    body: z.object({
      rfqId: z.string().uuid(),
      submit: z.boolean().default(false),
      quote: QuoteCreateSchema,
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Create draft quote with an option to submit it right away.',
  },
  updateQuote: {
    method: 'POST',
    path: `/liquidity/update-quote`,
    body: QuoteUpdateSchema,
    responses: {
      204: z.undefined(),
    },
    summary: 'Update draft or submitted quote with an option to submit if it is a draft.',
  },
  submitQuote: {
    method: 'POST',
    path: `/liquidity/submit-quote`,
    body: z.object({
      quoteId: z.string().uuid(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Submit draft quote.',
  },
  deleteQuote: {
    method: 'POST',
    path: `/liquidity/delete-quote`,
    body: z.object({
      quoteId: z.string().uuid(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Delete draft quote.',
  },
  cancelQuote: {
    method: 'POST',
    path: `/liquidity/cancel-quote`,
    body: z.object({
      quoteId: z.string().uuid(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Cancel submitted quote.',
  },
  getRFQMyQuotesRoundSummary: {
    method: 'GET',
    path: `/liquidity/rfq-my-quotes-round-summary`,
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
      rfqId: z.string().uuid(),
    }),
    responses: {
      200: z.object({
        totalCreatedQuotesQty: z.number(),
        mySubmittedQuotesQty: z.number(),
        loanCallOptionsQuotesQty: z.number(),
        retainerWorkingCapitalQuotesQty: z.number(),
      }),
    },
    summary: 'Get rfq round comparison.',
  },
  setRFQIgnoreState: {
    method: 'POST',
    path: `/liquidity/set-rfq-ignore-state`,
    body: z.object({
      rfqId: z.string().uuid(),
      round: z.number(),
      ignore: z.boolean(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Set RFQ ignore state.',
  },
  getRFQPreferences: {
    method: 'GET',
    path: `/liquidity/rfq-preferences`,
    query: z.object({
      id: z.string().uuid(),
      round: z.coerce.number().int().min(1).max(2),
    }),
    responses: {
      200: z.object({
        ignored: z.boolean(),
        hasRoundQuotes: z.boolean(),
      }),
    },
    summary: 'Get RFQ preferences.',
  },
})

export type LiquidityContractType = typeof liquidityContract
