import { initContract } from '@ts-rest/core'
import {
  LoadTDTemplate,
  ModelingPreviewResponse,
  PostModelingBody,
  PostTokenProfileBody,
  PutAdjustingBody,
  ProjectPathParam,
  ReferenceProjects,
  Section,
  TokenDesigner,
  Dashboard,
  ReferenceTokenDesigners,
  ReferenceProjectInfo,
  DistributionGroup,
  TokenDesignerGroups,
  TokenDesignerGroupsResponse,
  UpdateTDSectionMarkAsCompletedBody,
  TokenDesignerSectionCompletedStatus,
  ProfileDetails,
  CompareResponse,
} from '../types'
import { z } from 'zod'

const c = initContract()

export const tokenDesignerContract = c.router({
  getReferenceProjectsList: {
    method: 'GET',
    path: `/token-designer/reference-projects`,
    responses: {
      200: z.array(ReferenceProjectInfo),
    },
    summary: 'Get list of reference projects',
  },
  getReferenceProjectGroups: {
    method: 'GET',
    path: `/token-designer/reference-projects/:projectId/groups`,
    pathParams: ProjectPathParam,
    responses: {
      200: z.array(DistributionGroup),
    },
    summary: 'Get list of reference project`s groups',
  },
  getProjectReferences: {
    method: 'GET',
    path: `/token-designer/references`,
    responses: {
      200: ReferenceProjects,
    },
    summary: 'Get project references',
  },
  getProjectTemplates: {
    method: 'GET',
    path: `/token-designer/templates`,
    responses: {
      200: ReferenceTokenDesigners,
    },
    summary: 'Get project templates',
  },
  getTD: {
    method: 'GET',
    path: `/token-designer/schema/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: TokenDesigner,
    },
    summary: 'Get token designer for project',
  },
  loadTD: {
    method: 'PUT',
    path: `/token-designer/:projectId`,
    pathParams: ProjectPathParam,
    body: LoadTDTemplate,
    responses: {
      200: TokenDesigner,
    },
    summary: 'Upload a template into token designer for project',
  },
  getDashboard: {
    method: 'GET',
    path: '/token-designer/dashboard/:projectId',
    pathParams: ProjectPathParam,
    responses: {
      200: Dashboard,
    },
    summary: 'Get dashboard info for UI',
  },
  // Token Profile CRUD
  getTDTokenProfile: {
    method: 'GET',
    path: `/token-designer/token-profile/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: Section,
    },
    summary: 'Get token designer token profile for project',
  },
  putTDTokenProfile: {
    method: 'PUT',
    path: `/token-designer/token-profile/:projectId`,
    pathParams: ProjectPathParam,
    query: z.object({
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PostTokenProfileBody,
    responses: {
      201: TokenDesigner,
    },
    summary: 'Update profile values of token designer for project',
  },
  updateTDSectionMarkAsCompleted: {
    method: 'POST',
    path: `token-designer/token-profile/:projectId/update-mark-as-completed`,
    pathParams: ProjectPathParam,
    body: UpdateTDSectionMarkAsCompletedBody,
    responses: {
      200: TokenDesignerSectionCompletedStatus,
    },
    summary: 'Updates TD section mark as completed flag with boolean value',
  },
  // Modeling CRUD
  getTDModeling: {
    method: 'GET',
    path: `/token-designer/modeling/:projectId`,
    pathParams: ProjectPathParam,
    query: z.object({
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
    }),
    responses: {
      200: z.object({
        section: Section,
        preview: ModelingPreviewResponse.nullable(),
      }),
    },
    summary: 'Get modeling of token designer modeling for project',
  },
  compareTD: {
    method: 'GET',
    path: `/token-designer/compare/:projectId`,
    pathParams: ProjectPathParam,
    query: z.object({
      comparableProjectId: z.string().uuid(),
    }),
    responses: {
      200: CompareResponse,
    },
    summary: 'Compare token designer for project with reference project',
  },
  putTDModeling: {
    method: 'PUT',
    path: `/token-designer/modeling/:projectId`,
    pathParams: ProjectPathParam,
    query: z.object({
      preview: z
        .string()
        .optional()
        .default('true')
        .transform((value) => value === 'true'),
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PostModelingBody,
    responses: {
      201: z.object({
        tokenDesigner: TokenDesigner,
        preview: ModelingPreviewResponse.nullable(),
      }),
    },
    summary: 'Update modeling values of token designer for project',
  },
  getAdjusting: {
    method: 'GET',
    path: '/token-designer/adjusting/:projectId',
    pathParams: ProjectPathParam,
    responses: {
      201: z.object({
        section: Section,
      }),
    },
    summary: 'Get Adjusting data',
  },
  putAdjusting: {
    method: 'PUT',
    path: '/token-designer/adjusting/:projectId',
    pathParams: ProjectPathParam,
    query: z.object({
      complete: z
        .string()
        .optional()
        .default('false')
        .transform((value) => value === 'true'),
    }),
    body: PutAdjustingBody,
    responses: {
      201: z.object({
        tokenDesigner: TokenDesigner,
      }),
    },
    summary: 'Update adjusting section',
  },
  ensureTd: {
    method: 'GET',
    path: `/token-designer/ensure/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      204: z.undefined(),
    },
    summary: 'Create token designer from defi template if it does not exist.',
  },
  /**
   * updated API goes here
   */
  getProfileDetails: {
    method: 'GET',
    path: '/token-designer/token-profile-details/:projectId',
    pathParams: ProjectPathParam,
    responses: {
      200: ProfileDetails,
    },
    summary: 'Get project profile info',
  },
  getGroups: {
    method: 'GET',
    path: '/token-designer/groups/:projectId',
    pathParams: ProjectPathParam,
    responses: {
      200: TokenDesignerGroupsResponse,
    },
    summary: 'Get project groups',
  },
  updateGroups: {
    method: 'POST',
    path: '/token-designer/groups/:projectId',
    pathParams: ProjectPathParam,
    body: TokenDesignerGroups,
    responses: {
      200: TokenDesignerGroupsResponse,
    },
    summary: 'Update projects groups',
  },
})

export type TokenDesignerContractType = typeof tokenDesignerContract
