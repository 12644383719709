import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  UpdateAreasOfInterestBody,
  CompleteBody,
  InitProjectBody,
  OwnerDetailsBody,
  GetProgressResponseBody,
  GetProjectDetailsResponseBody,
  GetOwnerDetailsResponseBody,
  GetMemberInvitesResponseBody,
  GetMemberDetailsResponseBody,
  MemberDetailsBody,
  InitProjectResponse,
  GetAreasOfInterestResponseBody,
  TProject,
  UpdateProjectTypeResponse,
  UpdateProjectTypeBody,
  UpdateListedProjectBody,
  UpdateNotListedProjectBody,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const onboardingContract = c.router({
  initProject: {
    method: 'POST',
    path: `/onboarding/init-project`,
    responses: {
      200: InitProjectResponse,
    },
    body: InitProjectBody,
    summary: 'Create project and set research flag.',
  },
  updateProjectType: {
    method: 'POST',
    path: `/onboarding/project-type`,
    responses: {
      200: UpdateProjectTypeResponse,
    },
    body: UpdateProjectTypeBody,
    summary: 'Update project`s research flag.',
  },
  updateListedProjectDetails: {
    method: 'POST',
    path: `/onboarding/listed-project-details/:projectId`,
    responses: {
      200: TProject,
    },
    pathParams: ProjectPathParam,
    body: UpdateListedProjectBody,
    summary: 'Update listed project details.',
  },
  updateNotListedProjectDetails: {
    method: 'POST',
    path: `/onboarding/not-listed-project-details/:projectId`,
    responses: {
      200: TProject,
    },
    pathParams: ProjectPathParam,
    body: UpdateNotListedProjectBody,
    summary: 'Update not listed project details.',
  },
  updateAreasOfInterest: {
    method: 'POST',
    path: `/onboarding/areas-of-interest`,
    responses: {
      200: TProject,
    },
    body: UpdateAreasOfInterestBody,
    summary: 'Update areas of interest of the project.',
  },
  updateOwnerDetails: {
    method: 'POST',
    path: `/onboarding/owner-details`,
    responses: {
      200: GetOwnerDetailsResponseBody,
    },
    body: OwnerDetailsBody,
    summary: 'Update owner details of the project.',
  },
  complete: {
    method: 'POST',
    path: `/onboarding/complete`,
    responses: {
      204: z.undefined(),
    },
    body: CompleteBody,
    summary: 'Complete onboarding.',
  },
  getProgress: {
    method: 'GET',
    path: `/onboarding/:projectId/progress`,
    responses: {
      200: GetProgressResponseBody,
    },
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Get onboarding progress of the project.',
  },
  getProjectDetails: {
    method: 'GET',
    path: `/onboarding/:projectId/project-details`,
    responses: {
      200: GetProjectDetailsResponseBody,
    },
    pathParams: ProjectPathParam,
    summary: 'Get onboarding project details.',
  },
  getAreasOfInterest: {
    method: 'GET',
    path: `/onboarding/:projectId/areas-of-interest`,
    responses: {
      200: GetAreasOfInterestResponseBody,
    },
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Get areas of interest of the project.',
  },
  getOwnerDetails: {
    method: 'GET',
    path: `/onboarding/:projectId/owner-details`,
    responses: {
      200: GetOwnerDetailsResponseBody,
    },
    pathParams: ProjectPathParam,
    summary: 'Get onboarding owner details of the project.',
  },
  getMemberInvites: {
    method: 'GET',
    path: `/onboarding/:projectId/member-invites`,
    responses: {
      200: GetMemberInvitesResponseBody,
    },
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    summary: 'Get invited members of the project.',
  },
  updateMemberDetails: {
    method: 'POST',
    path: `/onboarding/member-details`,
    responses: {
      200: GetMemberDetailsResponseBody,
    },
    body: MemberDetailsBody,
    summary: 'Update members details.',
  },
})

export type OnboardingType = typeof onboardingContract
