import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  CreateMarketMakerEngagementRequestBody,
  GetMarketMakerEngagementResponseBody,
  GetMarketMakerEngagementsSummariesResponseBody,
  MarketMakerLeaderboardOptIns,
  MarketMakerPathParam,
  MarketMakerResult,
  UpdateMarketMakerEngagementRequestBody,
  UpdateMarketMakerLeaderboardOptIns,
} from '../types'

const c = initContract()

export const liquidityMarketMakerContract = c.router({
  getEngagedProjects: {
    method: 'GET',
    path: '/liquidity-market-maker/engaged-projects',
    responses: {
      200: z.array(
        z.object({
          projectId: z.string().uuid(),
          projectName: z.string(),
          imageUrl: z.string().nullable(),
        }),
      ),
    },
    summary: 'Get market maker engaged projects',
  },
  getLeaderboardOptIns: {
    method: 'GET',
    path: '/liquidity-market-maker/leaderboard-opt-ins',
    responses: {
      200: MarketMakerLeaderboardOptIns,
    },
    summary: 'Get market maker leaderboard opt-ins',
  },
  updateLeaderboardOptIns: {
    method: 'PUT',
    path: '/liquidity-market-maker/leaderboard-opt-ins',
    body: UpdateMarketMakerLeaderboardOptIns,
    responses: {
      204: z.undefined(),
    },
    summary: 'Update market maker leaderboard opt-ins',
  },
  getEngagements: {
    method: 'GET',
    path: '/liquidity-market-maker/engagements',
    responses: {
      200: GetMarketMakerEngagementsSummariesResponseBody,
    },
    summary: 'Get engagements of a market maker',
  },
  getEngagement: {
    method: 'GET',
    path: '/liquidity-market-maker/engagements/:engagementId',
    pathParams: z.object({
      engagementId: z.string().uuid(),
    }),
    responses: {
      200: GetMarketMakerEngagementResponseBody,
    },
    summary: 'Get engagement by Id.',
  },
  createEngagement: {
    method: 'POST',
    path: '/liquidity-market-maker/engagements',
    body: CreateMarketMakerEngagementRequestBody,
    responses: {
      204: z.undefined(),
    },
    summary: 'Create an engagement',
  },
  updateEngagement: {
    method: 'PUT',
    path: '/liquidity-market-maker/engagements/:engagementId',
    pathParams: z.object({
      engagementId: z.string().uuid(),
    }),
    body: UpdateMarketMakerEngagementRequestBody,
    responses: {
      204: z.undefined(),
    },
    summary: 'Update an engagement',
  },
  cancelEngagement: {
    method: 'PATCH',
    path: '/liquidity-market-maker/engagements/:engagementId/cancel',
    pathParams: z.object({
      engagementId: z.string().uuid(),
    }),
    body: z.undefined(),
    responses: {
      204: z.undefined(),
    },
    summary: 'Cancel an engagement',
  },
  getMarketMaker: {
    method: 'GET',
    path: '/liquidity-market-maker/:marketMakerId',
    pathParams: MarketMakerPathParam,
    query: z.object({
      projectId: z.string().uuid().optional(),
    }),
    responses: {
      200: MarketMakerResult,
    },
    summary: 'Get market maker by Id.',
  },
})

export type LiquidityMarketMakerContractType = typeof liquidityMarketMakerContract
