import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { customDateSchema } from '../types'
import { NotificationTypeEnumValues } from '@forgd/shared/enums'

const c = initContract()

export const notificationContract = c.router({
  getNotifications: {
    method: 'GET',
    path: `/notifications`,
    query: z.object({
      createdAt: customDateSchema.optional(),
    }),
    responses: {
      200: z.object({
        requestedAt: z.string(),
        items: z.array(
          z.object({
            id: z.string().uuid(),
            type: z.enum(NotificationTypeEnumValues),
            data: z.record(z.any()),
            createdAt: z.date(),
            read: z.boolean(),
          }),
        ),
      }),
    },
    summary: 'Get notifications.',
  },
  markAsRead: {
    method: 'PUT',
    path: `/notifications/mark-as-read`,
    body: z.object({
      untilDate: customDateSchema,
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Mark notifications as read.',
  },
  deleteAll: {
    method: 'DELETE',
    path: `/notifications/delete-all`,
    body: z.object({
      untilDate: customDateSchema,
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Delete all notifications.',
  },
  deleteById: {
    method: 'DELETE',
    path: `/notifications/delete`,
    body: z.object({
      id: z.string().uuid(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Delete notification by id.',
  },
  getUserPreferences: {
    method: 'GET',
    path: `/notifications/user/preferences`,
    responses: {
      200: z.object({
        telegram: z.boolean(),
        email: z.boolean(),
      }),
    },
    summary: 'Get user preferences.',
  },
  setUserPreferences: {
    method: 'POST',
    path: `/notifications/user/preferences`,
    body: z.object({
      telegram: z.boolean(),
      email: z.boolean(),
    }),
    responses: {
      204: z.undefined(),
    },
    summary: 'Set user preferences.',
  },
})

export type NotificationContractType = typeof notificationContract
