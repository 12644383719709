import { initContract } from '@ts-rest/core'
import { ProjectPathParam } from '../types/common'
import { GetDashboardDataQuery, GetDashboardDataResult, GetEngagementsHistoryResult } from '../types'

const c = initContract()

export const marketMakerMonitoringContract = c.router({
  getEngagementsHistory: {
    method: 'GET',
    path: `/market-maker-monitoring/engagements-history/:projectId`,
    pathParams: ProjectPathParam,
    responses: {
      200: GetEngagementsHistoryResult,
    },
    summary: 'Get market makers engagements history for a project',
  },
  getDashboardData: {
    method: 'GET',
    path: `/market-maker-monitoring/dashboard/:projectId`,
    query: GetDashboardDataQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetDashboardDataResult,
    },
    summary: 'Get dashboard data by project id',
  },
})

export type MarketMakerMonitoringContractType = typeof marketMakerMonitoringContract
