import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  ProjectDashboardMetricsResponse,
  TProject,
  TProjectCreateBody,
  TProjects,
  TUpdateProjectBody,
  UpdateListedProjectBody,
  UpdateNotListedProjectBody,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const projectsContract = c.router({
  getProjects: {
    method: 'GET',
    path: `/projects`,
    responses: {
      200: TProjects,
    },
    query: z.object({
      organizationId: z.string().uuid(),
      page: z.number().default(1),
      limit: z.number().default(10),
    }),
    summary: 'Get the current user projects',
  },
  getProjectDashboardMetrics: {
    method: 'GET',
    path: `/projects/:projectId/dashboard-metrics`,
    responses: {
      200: ProjectDashboardMetricsResponse,
    },
    pathParams: ProjectPathParam,
    summary: 'Get project dashboard metrics',
  },
  postProject: {
    method: 'POST',
    path: `/projects`,
    responses: {
      201: TProject,
    },
    body: TProjectCreateBody,
    summary: 'Create a project',
  },
  updateProject: {
    method: 'PATCH',
    path: `/projects/:projectId`,
    responses: {
      200: TProject,
    },
    pathParams: ProjectPathParam,
    body: TUpdateProjectBody,
    summary: 'Update project details',
  },
  updateProjectImage: {
    method: 'PATCH',
    path: `/projects/:projectId/update-image`,
    responses: {
      204: z.undefined(),
    },
    pathParams: ProjectPathParam,
    body: z.object({
      image: z.string(),
    }),
    summary: 'Update a project image',
  },
  updateListedProject: {
    method: 'POST',
    path: `/projects/:projectId/listed`,
    responses: {
      200: TProject,
    },
    pathParams: ProjectPathParam,
    body: UpdateListedProjectBody,
    summary: 'Update listed project details',
  },
  updateNotListedProject: {
    method: 'POST',
    path: `/projects/:projectId/not-listed`,
    responses: {
      200: TProject,
    },
    pathParams: ProjectPathParam,
    body: UpdateNotListedProjectBody,
    summary: 'Update not listed project details',
  },
  promoteResearchProject: {
    method: 'PATCH',
    path: '/projects/:projectId/promote-research',
    pathParams: ProjectPathParam,
    responses: {
      204: z.undefined(),
    },
    body: c.noBody(),
    summary: 'Promote research project to normal project',
  },
})

export type ProjectsContractType = typeof projectsContract
