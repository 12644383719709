import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetCryptoInfoResponseBody, GetCoinsResponseBody } from '../types'

const c = initContract()

export const coinGeckoContract = c.router({
  searchCoins: {
    method: 'GET',
    path: `/coins/search`,
    query: z.object({
      query: z.string().min(1),
    }),
    responses: {
      200: GetCoinsResponseBody,
    },
    summary: 'Search for coins.',
  },
  getCoinDetails: {
    method: 'GET',
    path: `/coins/:coingeckoId`,
    pathParams: z.object({
      coingeckoId: z.string(),
    }),
    responses: {
      200: GetCryptoInfoResponseBody,
    },
    summary: 'Get coin details.',
  },
})

export type CoinGeckoContractType = typeof coinGeckoContract
